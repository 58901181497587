// クエリパラメータに住所と緯度経度があるか判断
// 住所と緯度経度の両方がある場合のみTRUE
export const hasAddressAndLocation = (): boolean => {
  const decodeQuery = decodeURIComponent(window.location.search);
  const urlParams = new URLSearchParams(decodeQuery);

  const address = urlParams.get("address") ?? "";
  // eslint-disable-next-line no-constant-binary-expression
  const latitude = Number(urlParams.get("latitude")) ?? 0;
  // eslint-disable-next-line no-constant-binary-expression
  const longitude = Number(urlParams.get("longitude")) ?? 0;
  return address !== "" && latitude !== 0 && longitude !== 0;
};
