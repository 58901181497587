import React from "react";
import styled from "styled-components";

import color from "constants/color";

const Overlay = styled.div<{ isVisible: boolean; paddingHorizontal?: number }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  display: ${(props) => (props.isVisible ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  z-index: 3;
`;

const Content = styled.div<{
  width?: string;
  height?: string;
  paddingHorizontal?: number;
  paddingVertical?: number;
  canScroll?: boolean;
}>`
  width: ${({ paddingHorizontal = 0, width = "400px" }) => `calc(${width} - ${paddingHorizontal * 2}px)`};
  height: ${({ height = "250px" }) => height};
  padding: ${({ paddingVertical = 0 }) => paddingVertical}px ${({ paddingHorizontal = 0 }) => paddingHorizontal}px;
  border-radius: 8px;
  background-color: ${color.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: ${({ canScroll }) => (canScroll ? "scroll" : "hidden")};
  position: relative;
  z-index: 2;
`;

const ScrollOverlay = styled.div<{
  paddingHorizontal?: number;
  width?: string;
  height?: string;
  paddingVertical?: number;
}>`
  position: relative;
  z-index: 1;
  position: fixed;
  width: ${({ paddingHorizontal = 0, width = "400px" }) => `calc(${width} - ${paddingHorizontal * 2}px)`};
  height: ${({ height = "250px" }) => height};
  padding: ${({ paddingVertical = 0 }) => paddingVertical}px ${({ paddingHorizontal = 0 }) => paddingHorizontal}px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  pointer-events: none;
`;

const ScrollOverlayTop = styled.div`
  background-color: ${color.white};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  width: 100%;
  height: 20px;
`;

const ScrollOverlayBottom = styled.div`
  background-color: ${color.white};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  width: 100%;
  height: 20px;
`;

const Margin = styled.div<{ margin?: string }>`
  margin: ${({ margin }) => `${margin} 0`};
`;

const stopPropagation = (e: React.MouseEvent<HTMLElement>) => e.stopPropagation();

// 本体
const Modal = ({
  isVisible,
  onClose,
  children,
  width,
  height,
  paddingHorizontal,
  paddingVertical,
  canScroll,
}: {
  isVisible: boolean;
  onClose: () => void;
  children: JSX.Element;
  width?: string;
  height?: string;
  paddingHorizontal?: number;
  paddingVertical?: number;
  canScroll?: boolean;
}) => {
  return (
    <Overlay onClick={onClose} isVisible={isVisible} paddingHorizontal={paddingHorizontal}>
      <Content
        width={width}
        height={height}
        onClick={stopPropagation}
        paddingHorizontal={paddingHorizontal}
        paddingVertical={paddingVertical}
        canScroll={canScroll}
      >
        {canScroll ? (
          <>
            <ScrollOverlay width={width} height={height}>
              <ScrollOverlayTop />
              <ScrollOverlayBottom />
            </ScrollOverlay>
            <Margin margin="10px" />
            {children}
            <Margin margin="10px" />
          </>
        ) : (
          children
        )}
      </Content>
    </Overlay>
  );
};

export default Modal;
