import { multiFactor, signOut } from "firebase/auth";
import { Suspense, lazy, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Loading from "components/Loading";
import useGetUserMfa from "hooks/api/useGetUserMfa";
import useErrorModalStatusContext from "hooks/useErrorModalStatusContext";
import useFirebaseContext from "hooks/useFirebaseContext";
import { useNoticeModal } from "hooks/useModal";
import useUserContext from "hooks/useUserContext";
import { isBrowser, isTablet } from "utils/deviceDetect";
import { formatJapanesePhoneNumber } from "utils/phoneNumberUtils";

const MfaStatusPresenter = lazy(() =>
  isBrowser
    ? import("pages/MfaStatus/pc/MfaStatusPresenter")
    : isTablet
      ? import("pages/MfaStatus/tb/MfaStatusPresenter")
      : import("pages/MfaStatus/sp/MfaStatusPresenter")
);

// 本体
const MfaStatusContainer = () => {
  const navigate = useNavigate();
  const { auth } = useFirebaseContext();
  const { userMfaInfo, error: userMfaError, mutate: getUserMfaMutate } = useGetUserMfa();
  const { showErrorModal } = useErrorModalStatusContext();
  const [isMfaEnabled, setIsMfaEnabled] = useState<boolean>(false);
  const [mfaPhoneNumber, setMfaPhoneNumber] = useState<string>("");
  const { NoticeModal: ReLoginModal, showNoticeModal: showReLoginModal } = useNoticeModal();
  const { user } = useUserContext();

  const disableMfa = async () => {
    if (!auth.currentUser) {
      return;
    }
    // Check if the user is enroll in MFA.
    const checkMfaEnrollment = multiFactor(auth.currentUser).enrolledFactors;

    // Unenroll all the MFA entries in the current user by uid.
    for (let i = 0; i < checkMfaEnrollment.length; i++) {
      await multiFactor(auth.currentUser)
        .unenroll(checkMfaEnrollment[i].uid)
        .catch((e) => {
          if (e.code === "auth/requires-recent-login") {
            showReLoginModal(); // 再ログインを促すモーダルを表示
          } else {
            throw e;
          }
        });
    }

    getUserMfaMutate();
  };

  useEffect(() => {
    if (userMfaInfo != null) {
      setIsMfaEnabled(userMfaInfo.isMfaEnabled);
      setMfaPhoneNumber(userMfaInfo.mfaPhoneNumber);
    }
  }, [auth.currentUser, userMfaInfo]);

  useEffect(() => {
    if (userMfaError) {
      showErrorModal({ httpMethod: "get" });
    }
  }, [userMfaError, showErrorModal]);

  const toMfaSettingPage = useCallback(() => {
    if (auth.currentUser?.emailVerified) {
      navigate("/mfa-setting");
    } else {
      navigate("/mfa/email-verify");
    }
  }, [navigate, auth.currentUser?.emailVerified]);

  const logout = async () => {
    await signOut(auth);
    navigate("/login", { replace: true, state: { from: "/mfa" } });
  };

  return (
    <Suspense fallback={<Loading />}>
      <MfaStatusPresenter
        isLoading={userMfaInfo == null}
        isMfaEnabled={isMfaEnabled}
        mfaPhoneNumber={formatJapanesePhoneNumber(mfaPhoneNumber)}
        toMfaSettingPage={toMfaSettingPage}
        disableMfa={disableMfa}
        isRequiredMfa={user.isRequiredMfa}
      />
      <ReLoginModal
        title="再ログインが必要です"
        message={
          <div style={{ padding: "0 16px 0 16px" }}>
            <p>ログイン後に一定時間が経過したため、再ログインする必要があります。</p>
          </div>
        }
        buttonText="ログイン画面へ"
        onClick={logout}
      />
    </Suspense>
  );
};

export default MfaStatusContainer;
