import * as firebaseStorage from "firebase/storage";

import useFirebaseContext from "hooks/useFirebaseContext";

const AllowedOrderFileTypes = [
  "text/csv", // csv
  "application/pdf", // pdf
  "application/vnd.ms-excel", // xls
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // xlsx
  "application/msword", // doc
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // docs
  "text/plain", // text
  "image/png", // png
  "image/jpeg", // jpeg
];

const useFirebaseStorage = () => {
  const { storage, auth } = useFirebaseContext();

  const uploadOrderAttachment = async (contactId: string, file: File) => {
    if (auth.currentUser == null) return null;

    const uid = auth.currentUser?.uid;
    const timestamp = new Date().getTime();
    const fileKey = `users/${uid}/order_attachments/${contactId}-${timestamp}-${file.name}`;

    const ref = firebaseStorage.ref(storage, fileKey);
    await firebaseStorage.uploadBytes(ref, file);
    const url = await firebaseStorage.getDownloadURL(ref);

    return url;
  };

  const uploadOrderFile = async (contactId: string, file: File) => {
    const timestamp = new Date().getTime();
    const fileKey = `order_files/${contactId}-${timestamp}-${file.name}`;

    const ref = firebaseStorage.ref(storage, fileKey);
    await firebaseStorage.uploadBytes(ref, file);

    return fileKey;
  };

  const validateOrderFile = (file: File) => {
    if (!AllowedOrderFileTypes.includes(file.type)) {
      return false;
    }
    if (file.size > 5000000) {
      return false;
    }

    return true;
  };

  return {
    uploadOrderAttachment,
    uploadOrderFile,
    validateOrderFile,
  };
};

export default useFirebaseStorage;
