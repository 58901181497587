import { Suspense, lazy, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Loading from "components/Loading";
import useFirebaseAuth from "hooks/useFirebaseAuth";
import { isBrowser, isTablet } from "utils/deviceDetect";

const MfaEmailVerifyCompletePresenter = lazy(() =>
  isBrowser
    ? import("pages/MfaEmailVerify/Complete/pc/MfaEmailVerifyCompletePresenter")
    : isTablet
      ? import("pages/MfaEmailVerify/Complete/tb/MfaEmailVerifyCompletePresenter")
      : import("pages/MfaEmailVerify/Complete/sp/MfaEmailVerifyCompletePresenter")
);

const MfaEmailVerifyCompleteContainer = () => {
  const [verified, setVerified] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const navigate = useNavigate();
  const { verifyEmailVerificationCode } = useFirebaseAuth();

  const next = () => {
    navigate("/mfa-setting");
  };
  const back = () => {
    navigate("/mfa");
  };

  useEffect(() => {
    (async () => {
      const oobCode = new URLSearchParams(window.location.search).get("oobCode") || "";
      await verifyEmailVerificationCode(oobCode).catch((e) => {
        console.error(e);
        setErrorMessage(e.message);
      });
      setVerified(true);
    })();

    // 初回のみ実行させたいため,ESLintのwarningを無効化している
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Suspense fallback={<Loading />}>
      {verified && <MfaEmailVerifyCompletePresenter onNext={next} onBack={back} errorMessage={errorMessage} />}
    </Suspense>
  );
};

export default MfaEmailVerifyCompleteContainer;
