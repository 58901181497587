import { lazy, Suspense, useCallback, useEffect, useState } from "react";

import Loading from "components/Loading";
import { INIT_ACCOUNT, INIT_DEPARTMENT } from "constants/filter";
import { orderDetailDisplayPage } from "constants/orderDetailDisplayPage";
import { SortDirection, sortDirection } from "constants/sortDirection";
import useFindOrders from "hooks/api/useFindOrders";
import useGetOrder from "hooks/api/useGetOrder";
import useGetUsers from "hooks/api/useGetUsers";
import useStopSearchProperty from "hooks/api/useStopSearchProperty";
import { useDebounce } from "hooks/useDebounce";
import useErrorModalStatusContext from "hooks/useErrorModalStatusContext";
import { useGoogleAnalytics } from "hooks/useGoogleAnalytics";
import usePageTransition from "hooks/usePageTransition";
import { OrderStatusFilter } from "pages/OrderList/orderStatusFilter";
import { findUniqueDepartments } from "utils/accountUtils";
import { isBrowser, isTablet } from "utils/deviceDetect";
import { findFromLocalStorage, saveToLocalStorage } from "utils/localStorageUtils";

const IS_KEEP_ORDER_FILTER_KEY = "keep-orders-status";

const PAGE_SIZE = 20;

export type YearAndMonth = {
  year: string;
  month: string;
};

const OrderList = lazy(() =>
  isBrowser
    ? import("pages/OrderList/pc/OrderList")
    : isTablet
      ? import("pages/OrderList/tb/OrderList")
      : import("pages/OrderList/sp/OrderList")
);

const OrderListContainer = () => {
  const queryParameter = new URLSearchParams(window.location.search);

  const [searchKeyword, setSearchKeyword] = useState<string | undefined>();
  const { debouncedValue: debouncedSearchKeyword } = useDebounce(searchKeyword, 500);

  const [orderStatusFilter, setOrderStatusFilter] = useState<OrderStatusFilter | undefined>(
    (queryParameter.get("filter") as OrderStatusFilter) ?? OrderStatusFilter.all
  );
  const [orderCreatedAtSortOption, setOrderCreatedAtSortOption] = useState<SortDirection>(sortDirection.desc);

  const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);

  const [chosenAccount, setChosenAccount] = useState<Account | undefined>();
  const [chosenDepartment, setChosenDepartment] = useState<string | undefined>();
  const { accountList } = useGetUsers();
  const departmentList = findUniqueDepartments(accountList);
  const { stopSearchProperty } = useStopSearchProperty();

  const [orderId, setOrderId] = useState("");
  const [isVisible, setIsVisible] = useState(false);

  const { sendEvent } = useGoogleAnalytics(true);
  const onClickSortCreatedAt = useCallback(
    (direction: SortDirection) => {
      setOrderCreatedAtSortOption(direction);
      sendEvent({
        action: "依頼検索_依頼日_ソート",
        category: "sort",
        label: location.pathname.replace(/\/\w{18}($|\/)/, "/detail$1"),
      });
    },
    [sendEvent]
  );

  const {
    orders: orderList,
    pagination,
    error: ordersError,
    mutate: findOrdersMutate,
  } = useFindOrders({
    pageSize: PAGE_SIZE,
    pageNumber: currentPageNumber,
    orderType: orderCreatedAtSortOption,
    searchKeyword: debouncedSearchKeyword,
    contactId: chosenAccount?.id !== INIT_ACCOUNT.id ? chosenAccount?.id : undefined,
    department: chosenDepartment !== INIT_DEPARTMENT ? chosenDepartment : undefined,
    orderStatusFilter: orderStatusFilter ?? findFromLocalStorage(IS_KEEP_ORDER_FILTER_KEY, OrderStatusFilter.all),
  });

  const navigate = usePageTransition();

  const { order, error: orderDetailError, mutate: getOrderMutate } = useGetOrder(orderId);

  const { showErrorModal } = useErrorModalStatusContext();

  const onClickStopSearchButton = async (onSuccess?: () => void) => {
    const statusCode = await stopSearchProperty(order?.id ?? null).catch(() => {
      showErrorModal({
        httpMethod: "put",
      });
      return;
    });
    if (statusCode === 200) {
      onSuccess?.();
      findOrdersMutate();
      getOrderMutate();
    }
  };

  const onClickOrderRow = async (orderId: string) => {
    sendEvent({
      action: "工事詳細を開く_エリア",
      category: "other",
      label: "/order",
    });
    setIsVisible(true);
    setOrderId(orderId);
  };
  const onDrawerClose = () => {
    setIsVisible(false);
    setOrderId("");
  };

  useEffect(() => {
    if (ordersError || orderDetailError) {
      showErrorModal({ httpMethod: "get" });
    }
  }, [orderDetailError, ordersError, showErrorModal]);

  const toOrderDetail = (orderId: string): void => {
    sendEvent({
      action: "見積画面に遷移_ボタン",
      category: "button",
      label: "/order",
    });
    navigate(`/order/${orderId}`);
  };
  const toEditOrder = (orderId: string): void => {
    sendEvent({
      action: "依頼変更に遷移_リンク",
      category: "link",
      label: "/order",
    });
    navigate(`/order/${orderId}`, { state: { redirectDisplayPage: orderDetailDisplayPage.EDIT_ORDER } });
  };
  const toContract = (orderId: string): void => {
    sendEvent({
      action: "契約画面に遷移_ボタン",
      category: "button",
      label: "/order",
    });
    navigate(`/order/${orderId}/contract`);
  };
  const toBillingList = (yearAndMonth: YearAndMonth | undefined) => {
    sendEvent({
      action: "契約画面に遷移_ボタン",
      category: "button",
      label: "/order",
    });
    navigate(yearAndMonth ? `/billing?year=${yearAndMonth.year}&month=${yearAndMonth.month}` : `/billing`);
  };
  return (
    <Suspense fallback={<Loading />}>
      <OrderList
        isFirstFetch={
          searchKeyword == null &&
          chosenAccount == null &&
          chosenDepartment == null &&
          orderStatusFilter == null &&
          // usageページでリロード時に必要。一度でもfetch成功していなければ、all以外にできない。allのままならfirstFetchの可能性有とみなす
          findFromLocalStorage(IS_KEEP_ORDER_FILTER_KEY, OrderStatusFilter.all) === OrderStatusFilter.all
        }
        orderList={orderList}
        searchKeyword={searchKeyword ?? ""}
        updateSearchKeyword={setSearchKeyword}
        orderStatusFilter={orderStatusFilter ?? findFromLocalStorage(IS_KEEP_ORDER_FILTER_KEY, OrderStatusFilter.all)}
        setOrderStatusFilter={setOrderStatusFilter}
        setLocalStorage={(status: OrderStatusFilter) => saveToLocalStorage(IS_KEEP_ORDER_FILTER_KEY, status)}
        orderCreatedAtSortDirection={orderCreatedAtSortOption}
        updateOrderCreatedAtSortDirection={onClickSortCreatedAt}
        accountList={accountList ?? []}
        chosenAccount={chosenAccount ?? { id: "ALL", name: "すべて", department: "すべて" }}
        setChosenAccount={setChosenAccount}
        departmentList={departmentList ?? []}
        chosenDepartment={chosenDepartment ?? "すべて"}
        setChosenDepartment={setChosenDepartment}
        totalPageCount={pagination.totalPages ?? 1}
        currentPageNumber={currentPageNumber}
        setCurrentPageNumber={setCurrentPageNumber}
        isVisible={isVisible}
        constructionDisplayInDetail={order}
        onClickOrderRow={onClickOrderRow}
        onClickOrderStatus={toOrderDetail}
        onClickContractStatus={toContract}
        onClickBillingStatus={toBillingList}
        onCloseDetail={onDrawerClose}
        onClickConstructionEditButton={toEditOrder}
        onClickStopSearchButton={onClickStopSearchButton}
      />
    </Suspense>
  );
};

export default OrderListContainer;
