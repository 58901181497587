import { Dispatch, lazy, SetStateAction, Suspense, useEffect, useState } from "react";

import Loading from "components/Loading";
import { INIT_ACCOUNT, INIT_DEPARTMENT } from "constants/filter";
import { SortDirection, sortDirection as SORT_DIRECTION } from "constants/sortDirection";
import useCancelOption from "hooks/api/useCancelOption";
import useFindOption from "hooks/api/useFindOption";
import useFindOptions from "hooks/api/useFindOptions";
import useGetUsers from "hooks/api/useGetUsers";
import { useDebounce } from "hooks/useDebounce";
import useErrorModalStatusContext from "hooks/useErrorModalStatusContext";
import { useGoogleAnalytics } from "hooks/useGoogleAnalytics";
import { useInfoModal, useWarningModal } from "hooks/useModal";
import usePageTransition from "hooks/usePageTransition";
import { Department, findUniqueDepartments } from "utils/accountUtils";
import { isBrowser, isTablet, isMobile } from "utils/deviceDetect";

const PAGE_SIZE = 20;

const OptionListPresenter = lazy(() =>
  isBrowser
    ? import("pages/OptionList/pc/OptionListPresenter")
    : isTablet
      ? import("pages/OptionList/tb/OptionListPresenter")
      : import("pages/OptionList/sp/OptionListPresenter")
);

export type OptionListPresenterProps = {
  sortDirection: SortDirection;
  orderBy?: "createdAt";
  searchKeyword: string;
  options: Option[] | undefined;
  currentPageNumber: number;
  isDrawerVisible: boolean;
  chosenAccount: Account;
  chosenDepartment: string;
  departmentList: Department[];
  accountList: Account[];
  totalPageCount: number;
  selectedOption?: Option;
  setCurrentPageNumber: Dispatch<SetStateAction<number>>;
  InfoModal: JSX.Element;
  WarningModal: JSX.Element;
  selectedOptionId?: string;
  handleChangeSort: () => void;
  setChosenAccount: (value: Account | undefined) => void;
  setChosenDepartment: (value: string | undefined) => void;
  handleChangeSearchKeyword: (v: string) => void;
  handleCancelOption: (id: string) => Promise<void>;
  handleNavigateEdit: (id: string) => void;
  handleDrawerShow: (visible: boolean, optionId?: string) => void;
  handleNavigateCreate: () => void;
  handleNavigateOrder: (id: string) => void;
  handleChangeSelectedOptionId: (id?: string) => void;
};

const OptionListContainer = () => {
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [chosenAccount, setChosenAccount] = useState<Account | undefined>();
  const [chosenDepartment, setChosenDepartment] = useState<string | undefined>();
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [sortDirection, setSortDirection] = useState<SortDirection>(SORT_DIRECTION.desc);
  const [orderBy] = useState<"createdAt" | undefined>("createdAt");
  const [selectedOptionId, setSelectedOptionId] = useState<string | undefined>();
  const [infoModalState, setInfoModalState] = useState<{ title: string }>({ title: "" });
  const [warningModalState, setWarningModalState] = useState<{ title: string; label: string; onClick: () => void }>({
    title: "",
    label: "",
    onClick: () => null,
  });

  const { accountList } = useGetUsers();
  const { debouncedValue: debouncedSearchKeyword } = useDebounce(searchKeyword, 500);
  const { sendEvent } = useGoogleAnalytics(true);
  const navigate = usePageTransition();
  const { showErrorModal } = useErrorModalStatusContext();
  const { options, error, pagination, mutate } = useFindOptions({
    pageSize: PAGE_SIZE,
    pageNumber: currentPageNumber,
    orderType: sortDirection,
    orderBy: orderBy,
    contactId: chosenAccount?.id !== INIT_ACCOUNT.id ? chosenAccount?.id : undefined,
    department: chosenDepartment !== INIT_DEPARTMENT ? chosenDepartment : undefined,
    searchKeyword: debouncedSearchKeyword,
  });
  const { option, mutate: mutateFindById } = useFindOption(selectedOptionId);
  const { cancelOption } = useCancelOption();
  const { showInfoModal, InfoModal } = useInfoModal();
  const { WarningModal, showWarningModal, hideWarningModal } = useWarningModal();

  const departmentList = findUniqueDepartments(accountList);

  const handleChangeSearchKeyword = (v: string) => {
    setCurrentPageNumber(1);
    setSearchKeyword(v);
  };

  const handleChangeSort = () => {
    setSortDirection((prev) => (prev === "asc" ? "desc" : "asc"));
    setCurrentPageNumber(1);
  };

  const handleDrawerShow = (visible: boolean, optionId?: string) => {
    setIsDrawerVisible(visible);
    setSelectedOptionId(optionId);
  };

  const openCompleteModal = () => {
    setInfoModalState({
      title: "依頼を取り消しました",
    });
    showInfoModal();
  };

  const handleCancel = async (id: string) => {
    if (!selectedOptionId) return;
    const status = await cancelOption(id).catch(() => {
      showErrorModal({ httpMethod: "put" });
      hideWarningModal();
    });
    if (status === 200) {
      hideWarningModal();
      openCompleteModal();
      await mutate();
      await mutateFindById();
      // 依頼取消後に詳細モーダルを閉じる
      // PCはドロワーが常にローディング状態となってしまうため適用外
      if (isTablet || isMobile) {
        setSelectedOptionId(undefined);
      }
    }
  };

  const openSubmitModal = (id: string) => {
    setWarningModalState({
      title: "依頼を取り消しますか？",
      label: "取り消す",
      onClick: () => handleCancel(id),
    });
    showWarningModal();
  };

  const handleCancelOption = async (id: string) => {
    openSubmitModal(id);
  };

  const handleNavigateCreate = () => {
    navigate("/option/create");
  };

  const handleNavigateOrder = (id: string) => {
    navigate(`/order/${id}`);
  };

  const handleNavigateEdit = (id: string) => {
    sendEvent({
      action: "各種申請変更に遷移_リンク",
      category: "link",
      label: "/option",
    });
    navigate(`/option/${id}/update`);
  };

  useEffect(() => {
    if (error) showErrorModal({ httpMethod: "get" });
  }, [error, showErrorModal]);

  return (
    <Suspense fallback={<Loading />}>
      <OptionListPresenter
        selectedOption={option}
        orderBy={orderBy}
        sortDirection={sortDirection}
        accountList={accountList}
        chosenAccount={chosenAccount ?? INIT_ACCOUNT}
        setChosenAccount={setChosenAccount}
        chosenDepartment={chosenDepartment ?? INIT_DEPARTMENT}
        setChosenDepartment={setChosenDepartment}
        currentPageNumber={currentPageNumber}
        setCurrentPageNumber={setCurrentPageNumber}
        departmentList={departmentList}
        isDrawerVisible={isDrawerVisible}
        handleDrawerShow={handleDrawerShow}
        handleCancelOption={handleCancelOption}
        handleChangeSearchKeyword={handleChangeSearchKeyword}
        handleChangeSort={handleChangeSort}
        handleNavigateEdit={handleNavigateEdit}
        options={options}
        searchKeyword={searchKeyword}
        totalPageCount={pagination.totalPages ?? 1}
        handleNavigateCreate={handleNavigateCreate}
        handleNavigateOrder={handleNavigateOrder}
        InfoModal={<InfoModal title={infoModalState.title} />}
        handleChangeSelectedOptionId={(v) => setSelectedOptionId(v)}
        selectedOptionId={selectedOptionId}
        WarningModal={
          <WarningModal
            title={warningModalState.title}
            label={warningModalState.label}
            onClick={warningModalState.onClick}
          />
        }
      />
    </Suspense>
  );
};

export default OptionListContainer;
