export const REQUEST_SERVICES = {
  "道路使用・占有許可申請": "道路使用・占有許可申請",
  警備員手配: "警備員手配",
  挨拶代行: "挨拶代行",
  "鍵交換・引渡し代行": "鍵交換・引渡し代行",
  取扱説明書まとめ: "取扱説明書まとめ",
  検査立ち合い代行: "検査立ち合い代行",
  事務所手配: "事務所手配",
  コンテナ事務所手配: "コンテナ事務所手配",
  事務所機器レンタル: "事務所機器レンタル",
  用地仕入れサポート: "用地仕入れサポート",
  資材置き場手配: "資材置き場手配",
  電線移設立ち会い代行: "電線移設立ち会い代行",
  催事手配: "催事手配",
  現場見学会設営サポート: "現場見学会設営サポート",
  "除雪・除草業者手配": "除雪・除草業者手配",
  その他の申請: "その他の申請",
} as const;

export type RequestService = (typeof REQUEST_SERVICES)[keyof typeof REQUEST_SERVICES];

// 決められた順番で表示するためのリスト
export const REQUEST_SERVICES_LIST: RequestService[] = [
  REQUEST_SERVICES["道路使用・占有許可申請"],
  REQUEST_SERVICES["警備員手配"],
  REQUEST_SERVICES["挨拶代行"],
  REQUEST_SERVICES["鍵交換・引渡し代行"],
  REQUEST_SERVICES["取扱説明書まとめ"],
  REQUEST_SERVICES["検査立ち合い代行"],
  REQUEST_SERVICES["事務所手配"],
  REQUEST_SERVICES["コンテナ事務所手配"],
  REQUEST_SERVICES["事務所機器レンタル"],
  REQUEST_SERVICES["用地仕入れサポート"],
  REQUEST_SERVICES["資材置き場手配"],
  REQUEST_SERVICES["電線移設立ち会い代行"],
  REQUEST_SERVICES["催事手配"],
  REQUEST_SERVICES["現場見学会設営サポート"],
  REQUEST_SERVICES["除雪・除草業者手配"],
  REQUEST_SERVICES["その他の申請"],
];

type RequestServiceByCategory = {
  JA: string;
  list: RequestService[];
};

export const REQUEST_SERVICES_BY_CATEGORY: {
  BEFORE_SITE_WORK: RequestServiceByCategory;
  BEFORE_SITE_HANDOVER: RequestServiceByCategory;
  OFFICE_SETUP: RequestServiceByCategory;
  LAND_USE: RequestServiceByCategory;
  OTHER: RequestServiceByCategory;
} = {
  BEFORE_SITE_WORK: {
    JA: "現場作業前",
    list: [REQUEST_SERVICES["道路使用・占有許可申請"], REQUEST_SERVICES["警備員手配"], REQUEST_SERVICES["挨拶代行"]],
  },
  BEFORE_SITE_HANDOVER: {
    JA: "現場引渡し前",
    list: [
      REQUEST_SERVICES["鍵交換・引渡し代行"],
      REQUEST_SERVICES["取扱説明書まとめ"],
      REQUEST_SERVICES["検査立ち合い代行"],
    ],
  },
  OFFICE_SETUP: {
    JA: "事務所設置",
    list: [
      REQUEST_SERVICES["事務所手配"],
      REQUEST_SERVICES["コンテナ事務所手配"],
      REQUEST_SERVICES["事務所機器レンタル"],
    ],
  },
  LAND_USE: {
    JA: "土地活用・その他",
    list: [
      REQUEST_SERVICES["用地仕入れサポート"],
      REQUEST_SERVICES["資材置き場手配"],
      REQUEST_SERVICES["電線移設立ち会い代行"],
      REQUEST_SERVICES["催事手配"],
      REQUEST_SERVICES["現場見学会設営サポート"],
      REQUEST_SERVICES["除雪・除草業者手配"],
    ],
  },
  OTHER: {
    JA: "その他",
    list: [REQUEST_SERVICES["その他の申請"]],
  },
};
