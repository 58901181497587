import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import Loading from "components/Loading";
import color from "constants/color";
import { useGoogleAnalytics } from "hooks/useGoogleAnalytics";

// パーツ定義
const BaseButton = styled.button<{
  buttonType: "primary" | "secondary" | "danger";
  width: string;
  height: string;
  paddingHorizontal: string;
  disabled: boolean;
}>`
  display: block;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 7px ${({ paddingHorizontal }) => paddingHorizontal};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  background-color: ${({ disabled, buttonType }) =>
    disabled ? color.text.disabled : buttonType === "primary" ? color.text.link : color.white};
  border: 1px solid
    ${({ disabled, buttonType }) =>
      disabled ? color.text.disabled : buttonType === "danger" ? color.warning : color.text.link};
  border-radius: 4px;
  color: ${({ disabled, buttonType }) =>
    disabled
      ? color.white
      : buttonType === "primary"
        ? color.white
        : buttonType === "secondary"
          ? color.text.link
          : color.warning};
  font-weight: ${({ buttonType }) => (buttonType === "primary" ? "bold" : "normal")};

  @media (hover: hover) {
    :hover {
      background-color: ${({ disabled, buttonType }) =>
        disabled
          ? color.text.disabled
          : buttonType === "primary"
            ? color.text.linkDark
            : buttonType === "secondary"
              ? color.text.linkLight
              : color.attentionLight};
      border: 1px solid
        ${({ disabled, buttonType }) =>
          disabled
            ? color.text.disabled
            : buttonType === "primary"
              ? color.text.linkDark
              : buttonType === "secondary"
                ? color.text.link
                : color.warning};
    }
  }

  @media (hover: none) {
    :active {
      background-color: ${({ disabled, buttonType }) =>
        disabled
          ? color.text.disabled
          : buttonType === "primary"
            ? color.text.linkDark
            : buttonType === "secondary"
              ? color.text.linkLight
              : color.attentionLight};
      border: 1px solid
        ${({ disabled, buttonType }) =>
          disabled
            ? color.text.disabled
            : buttonType === "primary"
              ? color.text.linkDark
              : buttonType === "secondary"
                ? color.text.link
                : color.warning};
    }
  }
`;
const Label = styled.span<{ hasIcon: boolean }>`
  display: inline-block;
  ${({ hasIcon }) =>
    hasIcon &&
    `
    margin-left: 8px;
  `};
`;
const SubLabel = styled.span`
  display: block;
  font-size: 12px;
  color: ${color.text.gray};
`;

const Button = ({
  label,
  onClick,
  subLabel,
  type = "primary",
  width = "160px",
  height = "48px",
  paddingHorizontal = "16px",
  disabled = false,
  icon,
  loadingIcon,
  testId,
}: {
  label: string;
  onClick: () => Promise<void> | void;
  subLabel?: string;
  type?: "primary" | "secondary" | "danger";
  width?: string;
  height?: string;
  paddingHorizontal?: string;
  disabled?: boolean;
  icon?: IconDefinition;
  loadingIcon?: boolean;
  testId?: string;
}) => {
  const location = useLocation();
  const { sendEvent } = useGoogleAnalytics(true);
  const [isBusy, setIsBusy] = useState(false);

  const handler = useCallback(async () => {
    if (!disabled && !isBusy) {
      setIsBusy(true);
      try {
        await onClick();
      } catch (error) {
        setIsBusy(false);
      }
      sendEvent({
        action: `${label}_ボタン`,
        category: "button",
        label: location.pathname.replace(/\/\w{18}($|\/)/, "/detail$1"),
      });
      setIsBusy(false);
    }
  }, [disabled, isBusy, onClick, sendEvent, label, location.pathname]);
  return (
    <BaseButton
      buttonType={type}
      width={width}
      height={height}
      paddingHorizontal={paddingHorizontal}
      disabled={disabled || isBusy}
      onClick={handler}
      data-testid={testId}
    >
      <span style={{ display: "block" }}>
        {icon ? loadingIcon && isBusy ? <Loading /> : <FontAwesomeIcon icon={icon} /> : isBusy && <Loading />}
        <Label hasIcon={icon ? true : isBusy}>{label}</Label>
        {subLabel && <SubLabel>{subLabel}</SubLabel>}
      </span>
    </BaseButton>
  );
};

export default Button;
