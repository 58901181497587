import { AuthErrorCodes, sendPasswordResetEmail } from "firebase/auth";
import { lazy, useState, Suspense } from "react";
import { useNavigate } from "react-router-dom";

import Loading from "components/Loading";
import useFirebaseContext from "hooks/useFirebaseContext";
import { useNoticeModal } from "hooks/useModal";
import { isBrowser, isTablet } from "utils/deviceDetect";

const PasswordResetPresenter = lazy(() =>
  isBrowser
    ? import("pages/PasswordReset/pc/PasswordResetPresenter")
    : isTablet
      ? import("pages/PasswordReset/tb/PasswordResetPresenter")
      : import("pages/PasswordReset/sp/PasswordResetPresenter")
);

const createErrorMessage = (code: string): string => {
  switch (code) {
    case AuthErrorCodes.INVALID_EMAIL:
    case AuthErrorCodes.USER_DELETED:
      return "メールアドレスが正しくありません";
    default:
      return "予期せぬエラーが発生しました。しばらく時間をおいてお試しください";
  }
};

const PasswordResetContainer = () => {
  const { auth } = useFirebaseContext();
  const navigate = useNavigate();
  const { NoticeModal, showNoticeModal, hideNoticeModal } = useNoticeModal();

  const [email, setEmail] = useState("");
  const canSubmit = !email;

  const [errorMessage, setErrorMessage] = useState("");

  const onSend = async () => {
    await sendPasswordResetEmail(auth, email)
      .then(() => showNoticeModal())
      .catch((e) => {
        setErrorMessage(createErrorMessage(e.code));
      });
  };

  const handleClose = () => {
    hideNoticeModal();
    navigate("/", { replace: true });
  };

  return (
    <Suspense fallback={<Loading />}>
      <PasswordResetPresenter
        email={email}
        errorMessage={errorMessage}
        canSubmit={canSubmit}
        setEmail={setEmail}
        onSend={onSend}
        onBack={() => navigate(-1)}
        handleClose={handleClose}
        NoticeModal={NoticeModal}
      />
    </Suspense>
  );
};

export default PasswordResetContainer;
