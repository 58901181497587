import { AuthErrorCodes, applyActionCode, checkActionCode, multiFactor } from "firebase/auth";
import { lazy, Suspense, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Loading from "components/Loading";
import useFirebaseContext from "hooks/useFirebaseContext";
import { useInfoModal } from "hooks/useModal";
import { isBrowser, isTablet } from "utils/deviceDetect";

const MfaResetPresenter = lazy(() =>
  isBrowser
    ? import("pages/MfaReset/pc/MfaResetPresenter")
    : isTablet
      ? import("pages/MfaReset/pc/MfaResetPresenter")
      : import("pages/MfaReset/pc/MfaResetPresenter")
);

const createErrorMessage = (code: string): string => {
  switch (code) {
    case AuthErrorCodes.EXPIRED_OOB_CODE:
      return "パスワードリセットコードの有効期限が切れています。再度申請してください";
    case AuthErrorCodes.INVALID_OOB_CODE:
      return "パスワードリセットコードが無効です。再度申請してください";
    case AuthErrorCodes.USER_DISABLED:
      return "ユーザーが無効になっています。お問い合わせください";
    case AuthErrorCodes.USER_DELETED:
      return "ユーザーが存在しません。お問い合わせください";
    case AuthErrorCodes.WEAK_PASSWORD:
      return "新しいパスワードが脆弱です。再度入力してください";
    default:
      return "予期せぬエラーが発生しました。しばらく時間をおいてお試しください";
  }
};

const MfaResetContainer = () => {
  const { auth } = useFirebaseContext();
  const navigate = useNavigate();
  const [oobCode, setOobCode] = useState("");
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { InfoModal, showInfoModal, hideInfoModal } = useInfoModal();

  const disableMfa = async () => {
    if (!auth.currentUser) {
      return;
    }

    const checkMfaEnrollment = multiFactor(auth.currentUser).enrolledFactors;
    for (let i = 0; i < checkMfaEnrollment.length; i++) {
      await multiFactor(auth.currentUser)
        .unenroll(checkMfaEnrollment[i].uid)
        .catch((e) => {
          throw e;
        });
    }
  };

  const onSend = async () => {
    await applyActionCode(auth, oobCode).catch(function (e) {
      console.log(e);
      throw e;
    });

    disableMfa();
    showInfoModal();
  };

  const handleClose = () => {
    hideInfoModal();
    navigate("/", { replace: true });
  };

  const checkActionCodeAndSetEmail = useCallback(async () => {
    const queryParams = new URLSearchParams(window.location.search);
    const oobCode = queryParams.get("oobCode") || "";
    setOobCode(oobCode);

    const info = await checkActionCode(auth, oobCode).catch((e) => {
      // Error occurred during confirmation. The code might have expired or the
      // link has been used before.
      console.error(e);
      setErrorMessage(createErrorMessage(e.code));

      throw e;
    });

    if (info) {
      console.info(info);
      if (info.data.email) {
        setEmail(info.data.email);
      }
    }
  }, [auth]);

  useEffect(() => {
    checkActionCodeAndSetEmail();
  }, [checkActionCodeAndSetEmail]);

  return (
    <Suspense fallback={<Loading />}>
      <MfaResetPresenter
        email={email}
        errorMessage={errorMessage}
        onSend={onSend}
        InfoModal={InfoModal}
        onClose={handleClose}
      />
    </Suspense>
  );
};

export default MfaResetContainer;
