import dayjs from "dayjs";
import { ChangeEvent, Dispatch, lazy, SetStateAction, Suspense, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Loading from "components/Loading";
import { REQUEST_SERVICES } from "constants/option";
import useFindOption from "hooks/api/useFindOption";
import useFindOrders from "hooks/api/useFindOrders";
import useGetOrder from "hooks/api/useGetOrder";
import useUpdateOption from "hooks/api/useUpdateOption";
import { useDebounce } from "hooks/useDebounce";
import { useErrorModal, useInfoModal } from "hooks/useModal";
import usePageTransition from "hooks/usePageTransition";
import { isBrowser, isTablet } from "utils/deviceDetect";

const OptionUpdatePresenter = lazy(() =>
  isBrowser
    ? import("pages/OptionUpdate/pc/OptionUpdatePresenter")
    : isTablet
      ? import("pages/OptionUpdate/tb/OptionUpdatePresenter")
      : import("pages/OptionUpdate/sp/OptionUpdatePresenter")
);

export type OptionUpdatePresenterProps = {
  optionLoaded: boolean;
  onSubmit: () => void;
  navOptionList: () => void;
  navRelatedOrder: (id: string) => void;
  formValues: {
    orders: Order[] | undefined;
    name: string;
    requestServices: string[];
    searchActive: boolean;
    otherRequestContent: string;
    province: string;
    district: string;
    street: string;
    remarks: string;
    order?: { id: string; name: string };
    usingStartTime: string;
    usingEndTime: string;
    handleChangeName: (name: string, orderId?: string) => void;
    handleChangeRequestServices: (service: string) => void;
    handleChangeOtherRequestContent: (e: ChangeEvent<HTMLTextAreaElement>) => void;
    handleChangeProvince: (province: string) => void;
    handleChangeDistrict: (e: ChangeEvent<HTMLInputElement>) => void;
    handleChangeStreet: (e: ChangeEvent<HTMLInputElement>) => void;
    handleChangeRemarks: (e: ChangeEvent<HTMLTextAreaElement>) => void;
    handleChangeOrder: (order: { id: string; name: string }) => void;
    handleChangeSearchActive: (active: boolean) => void;
    handleChangeUsingStartTime: (time: string) => void;
    handleChangeUsingEndTime: (time: string) => void;
    errors: {
      isErrorName: boolean;
      isErrorRequestServices: boolean;
      isErrorOtherRequestContent: boolean;
      isErrorProvince: boolean;
      isErrorDistrict: boolean;
      isErrorStreet: boolean;
      isErrorRemarks: boolean;
      isErrorUsingStartTime: boolean;
      isErrorUsingEndTime: boolean;
      isErrorUsingTime: boolean;
    };
    canSubmit: boolean;
    setOrdersPageIndex: Dispatch<SetStateAction<number>>;
    isLoadingOrders?: boolean;
    hasMoreOrders: boolean;
  };
  InfoModal: JSX.Element;
  ErrorModal: JSX.Element;
};

const OptionUpdateContainer = () => {
  const [name, setName] = useState("");
  const [requestServices, setRequestServices] = useState<string[]>([]);
  const [otherRequestContent, setOtherRequestContent] = useState("");
  const [province, setProvince] = useState("");
  const [district, setDistrict] = useState("");
  const [street, setStreet] = useState("");
  const [remarks, setRemarks] = useState("");
  const [usingStartTime, setUsingStartTime] = useState<string>("");
  const [usingEndTime, setUsingEndTime] = useState<string>("");
  const [order, setOrder] = useState<{ id: string; name: string }>();
  const [searchActive, setSearchActive] = useState(false);
  const [ordersPageIndex, setOrdersPageIndex] = useState(1);
  const [infoModalState, setInfoModalState] = useState<{ title: string; onClick: () => void }>({
    title: "",
    onClick: () => null,
  });
  const [errorModalState, setErrorModalState] = useState<{ httpMethod: HttpMethod; message?: string }>({
    httpMethod: "post",
    message: "",
  });

  const beautifyName = (name: string) => name.trim();
  const { id } = useParams<{ id: string }>();
  const { debouncedValue: debouncedSearchKeyword } = useDebounce(name, 500);
  const { updateOption } = useUpdateOption();
  const navigate = usePageTransition();
  const { option, mutate } = useFindOption(id);
  const { orders, isLoading, pagination } = useFindOrders({
    searchKeyword: beautifyName(debouncedSearchKeyword),
    orderStatusFilter: "all",
    orderType: "desc",
    pageNumber: ordersPageIndex,
    pageSize: 25,
    parkingUsageDateFilter: "notExpired",
  });
  const { trigger } = useGetOrder("");
  const { InfoModal, showInfoModal } = useInfoModal();
  const { ErrorModal, showErrorModal } = useErrorModal();

  const handleChangeOrder = async (order: { id: string; name: string }) => setOrder(order);
  const handleChangeName = async (name: string, orderId?: string) => {
    setName(name);
    if (!orderId) {
      setOrder(undefined);
      setUsingStartTime("");
      setUsingEndTime("");
      return;
    }

    setOrder({ id: orderId, name });

    const response = await trigger(orderId);
    if (response) {
      setUsingStartTime(response.startDate);
      setUsingEndTime(response.endDate);
    }
  };
  const handleChangeOtherRequestContent = (e: ChangeEvent<HTMLTextAreaElement>) =>
    setOtherRequestContent(e.target.value);
  const handleChangeDistrict = (e: ChangeEvent<HTMLInputElement>) => setDistrict(e.target.value);
  const handleChangeProvince = (province: string) => setProvince(province);
  const handleChangeStreet = (e: ChangeEvent<HTMLInputElement>) => setStreet(e.target.value);
  const handleChangeRemarks = (e: ChangeEvent<HTMLTextAreaElement>) => setRemarks(e.target.value);
  const handleChangeRequestServices = (service: string) => {
    // その他の申請が選択されている場合、選択解除時に入力内容をクリア
    if (service === REQUEST_SERVICES["その他の申請"] && requestServices.includes(REQUEST_SERVICES["その他の申請"]))
      setOtherRequestContent("");
    if (requestServices.includes(service)) setRequestServices(requestServices.filter((item) => item !== service));
    else setRequestServices([...requestServices, service]);
  };
  const handleChangeSearchActive = (active: boolean) => setSearchActive(active);
  const handleChangeUsingStartTime = (time: string) => setUsingStartTime(time);
  const handleChangeUsingEndTime = (time: string) => setUsingEndTime(time);

  const hasMoreOrders = pagination.totalPages > ordersPageIndex;
  const isMaxLengthError = (value: string, maxLength = 255) => value.length > maxLength;
  const isErrorName = name.length === 0;
  const isErrorRequestServices = requestServices.length === 0;
  const isErrorOtherRequestContent =
    requestServices.includes(REQUEST_SERVICES["その他の申請"]) &&
    (otherRequestContent.length === 0 || isMaxLengthError(otherRequestContent));
  const isErrorProvince = (district.length !== 0 || street.length !== 0) && province.length === 0;
  const isErrorDistrict = (province.length !== 0 || street.length !== 0) && district.length === 0;
  const isErrorStreet = (province.length !== 0 || district.length !== 0) && street.length === 0;
  const isErrorUsingStartTime = false;
  const isErrorUsingEndTime = dayjs(usingStartTime).isAfter(dayjs(usingEndTime));
  const isErrorUsingTime = (!usingStartTime && !!usingEndTime) || (!!usingStartTime && !usingEndTime);
  const isErrorRemarks = isMaxLengthError(remarks);
  const isChangedName = option?.name !== name;
  const isChangedRequestServices =
    JSON.stringify(option?.requestService.sort()) !== JSON.stringify(requestServices.sort());
  const isChangedOtherRequestContent = option?.otherRequestContent !== otherRequestContent;
  const isChangedProvince = option?.province !== province;
  const isChangedDistrict = option?.district !== district;
  const isChangedStreet = option?.street !== street;
  const isChangedRemarks = option?.remarks !== remarks;
  const isChangedOrder = option?.order?.id !== order?.id;
  const isChangedUsingStartTime = option?.usingStartTime !== usingStartTime;
  const isChangedUsingEndTime = option?.usingEndTime !== usingEndTime;
  const isChanged =
    isChangedName ||
    isChangedRequestServices ||
    isChangedOtherRequestContent ||
    isChangedProvince ||
    isChangedDistrict ||
    isChangedStreet ||
    isChangedRemarks ||
    isChangedOrder ||
    isChangedUsingStartTime ||
    isChangedUsingEndTime;
  const canSubmit =
    !isErrorName &&
    !isErrorRequestServices &&
    !isErrorOtherRequestContent &&
    !isErrorProvince &&
    !isErrorDistrict &&
    !isErrorStreet &&
    !isErrorRemarks &&
    !isErrorUsingStartTime &&
    !isErrorUsingEndTime &&
    !isErrorUsingTime &&
    isChanged;
  const errors = {
    isErrorName,
    isErrorRequestServices,
    isErrorOtherRequestContent,
    isErrorProvince,
    isErrorDistrict,
    isErrorStreet,
    isErrorRemarks,
    isErrorUsingStartTime,
    isErrorUsingEndTime,
    isErrorUsingTime,
  };
  const formValues = {
    orders,
    searchActive,
    name,
    requestServices,
    otherRequestContent,
    province,
    district,
    street,
    remarks,
    order,
    usingStartTime,
    usingEndTime,
    handleChangeName,
    handleChangeRequestServices,
    handleChangeOtherRequestContent,
    handleChangeProvince,
    handleChangeDistrict,
    handleChangeStreet,
    handleChangeRemarks,
    handleChangeOrder,
    handleChangeSearchActive,
    handleChangeUsingStartTime,
    handleChangeUsingEndTime,
    errors,
    canSubmit,
    isLoadingOrders: isLoading,
    setOrdersPageIndex,
    hasMoreOrders,
    isChanged,
  };

  const navOptionList = () => navigate("/option");

  const navRelatedOrder = (id: string) => window.open(`/order/${id}`, "_blank");

  const openInfoModal = () => {
    setInfoModalState({
      title: "依頼内容を変更しました",
      onClick: () => navigate("/option"),
    });
    showInfoModal();
  };

  const onSubmit = async () => {
    const status = await updateOption({
      id,
      name,
      requestService: requestServices,
      orderId: order?.id ?? null,
      otherRequestContent,
      province,
      district,
      street,
      remarks,
      usingStartTime: usingStartTime ? dayjs(usingStartTime).format("YYYY-MM-DD") : null,
      usingEndTime: usingEndTime ? dayjs(usingEndTime).format("YYYY-MM-DD") : null,
    }).catch((e) => {
      if (e.response.status === 409) {
        setErrorModalState({
          httpMethod: "post",
          message: "既存の検索依頼と重複した名前の申請依頼です",
        });
      } else if (e.response.status === 428) {
        setErrorModalState({
          httpMethod: "post",
          message: "選択した検索依頼の駐車場貸出終了予定日を過ぎているため、申請できません",
        });
      } else if (e.response.status === 410) {
        setErrorModalState({
          httpMethod: "post",
          message: "取り消し済みの依頼です",
        });
      } else if (e.response.status === 422) {
        setErrorModalState({
          httpMethod: "post",
          message: "入力された内容は、\n登録されている依頼内容と同じです",
        });
      } else {
        setErrorModalState({ httpMethod: "post" });
      }
      showErrorModal();
    });
    if (status === 200) {
      openInfoModal();
      await mutate();
    }
  };

  const setUp = useCallback(() => {
    if (option) {
      setName(option.name);
      setRequestServices(option.requestService);
      setOtherRequestContent(option.otherRequestContent);
      setProvince(option.province);
      setDistrict(option.district);
      setStreet(option.street);
      setRemarks(option.remarks);
      setOrder(option.order);
      setUsingStartTime(option.usingStartTime ?? "");
      setUsingEndTime(option.usingEndTime ?? "");
    }
  }, [option]);

  useEffect(() => {
    setUp();
  }, [setUp]);

  return (
    <Suspense fallback={<Loading />}>
      <OptionUpdatePresenter
        optionLoaded={!!option}
        formValues={formValues}
        onSubmit={onSubmit}
        navOptionList={navOptionList}
        navRelatedOrder={navRelatedOrder}
        InfoModal={<InfoModal title={infoModalState.title} onClick={infoModalState.onClick} />}
        ErrorModal={<ErrorModal httpMethod={errorModalState.httpMethod} message={errorModalState.message} />}
      />
    </Suspense>
  );
};

export default OptionUpdateContainer;
