import dayjs from "dayjs";
import { lazy, Suspense, useCallback, useEffect, useMemo, useState } from "react";

import Loading from "components/Loading";
import useFindMonthlyBillingAttachment from "hooks/api/useFindMonthlyBillingAttachment";
import useFindMonthlyBillings from "hooks/api/useFindMonthlyBillings";
import useFindMonthlyBillingSummary from "hooks/api/useFindMonthlyBillingSummary";
import useFindNumberOfMonthlyBillings from "hooks/api/useFindNumberOfMonthlyBillings";
import useErrorModalStatusContext from "hooks/useErrorModalStatusContext";
import { useGoogleAnalytics } from "hooks/useGoogleAnalytics";
import useUserContext from "hooks/useUserContext";
import {
  BillingSort,
  BillingSortColumn,
  convertSortColumnToName,
  findBillingSortColumnAndDirection,
  initialBillingSort,
  update,
} from "pages/Billing/pc/billingSortColumn";
import useBillingCommonContainer from "pages/Billing/useBillingCommonContainer";
import { buildDate } from "utils/dateUtils";
import { isBrowser, isTablet } from "utils/deviceDetect";
import { downloadPdf } from "utils/fileUtils";

const MonthlyBillingListPresenter = lazy(() =>
  isBrowser
    ? import("pages/Billing/pc/MonthlyBillingListPresenter")
    : isTablet
      ? import("pages/Billing/tb/MonthlyBillingListPresenter")
      : import("pages/Billing/sp/MonthlyBillingListPresenter")
);

const PAGE_SIZE = 20;

const MonthlyBillingListContainer = () => {
  const {
    yearsOfUse,
    searchKeyword,
    setSearchKeyword,
    currentPageNumber,
    setCurrentPageNumber,
    chosenAccount,
    setChosenAccount,
    chosenDepartment,
    setChosenDepartment,
    accountList,
    departments,
    billingYear,
    setBillingYear,
    billingMonth,
    setBillingMonth,
    toUsageDetail,
  } = useBillingCommonContainer();

  const { user } = useUserContext();

  const { monthly } = useFindNumberOfMonthlyBillings(billingYear, `${user.billingClosingDay}`);

  const { sendEvent } = useGoogleAnalytics(true);
  const [billingSort, setBillingSort] = useState<BillingSort>(initialBillingSort);
  const updateBillingSort = (column: BillingSortColumn, previous: BillingSort): void => {
    setBillingSort(update(column, previous));
    sendEvent({
      action: `請求_${convertSortColumnToName(column)}_ソート`,
      category: "sort",
      label: location.pathname.replace(/\/\w{18}($|\/)/, "/detail$1"),
    });
  };
  const onClickSort = (column: BillingSortColumn, previous: BillingSort) => updateBillingSort(column, previous);
  const { sortColumn, sortDirection } = findBillingSortColumnAndDirection(billingSort);

  const {
    billingList,
    pagination,
    error: billingsError,
  } = useFindMonthlyBillings({
    pageSize: PAGE_SIZE,
    pageNumber: currentPageNumber,
    sortDirection: sortDirection,
    sortColumn: sortColumn,
    billingYear: billingYear,
    billingMonth: billingMonth.month,
    billingClosingDay: `${user.billingClosingDay}`,
    contactId: chosenAccount.id !== "ALL" && chosenAccount.id !== "" ? chosenAccount.id : undefined,
    department: chosenDepartment !== "すべて" && chosenDepartment !== "" ? chosenDepartment : undefined,
    searchKeyword: searchKeyword,
  });

  // billing/monthly/:yearのfetchが完了したら、billingMonthを更新する
  const setBillingMonthAfterFetch = useCallback(() => {
    if (monthly.length > 1) {
      setBillingMonth((prev) => monthly.find((m) => m.month === prev.month) || monthly[0]);
    }
  }, [monthly, setBillingMonth]);

  useEffect(() => {
    setBillingMonthAfterFetch();
  }, [billingsError, setBillingMonthAfterFetch]);

  const billingClosingDate = useMemo(() => {
    const lastDayOfSelectBillingMonth = dayjs()
      .set("month", Number(billingMonth.month) - 1)
      .endOf("month")
      .format("DD");
    // 月末日が請求締め日より前の場合は請求締め日を月末日にする
    if (user.billingClosingDay >= Number(lastDayOfSelectBillingMonth))
      return buildDate(billingYear, billingMonth.month, lastDayOfSelectBillingMonth);

    return buildDate(billingYear, billingMonth.month, user.billingClosingDay);
  }, [billingMonth.month, billingYear, user.billingClosingDay]);

  const { monthlyBillingSummary, error: monthlyBillingSummaryError } = useFindMonthlyBillingSummary({
    billingYear: billingYear,
    billingMonth: billingMonth.month,
  });

  const { error: attachmentError, trigger } = useFindMonthlyBillingAttachment(monthlyBillingSummary?.id ?? null);

  const { showErrorModal } = useErrorModalStatusContext();

  const onClickBillingDownloadButton = useCallback(
    async (monthlyBillingId: string) => {
      const attachment = await trigger(monthlyBillingId);
      downloadPdf(attachment, `${user.companyName}御中_${billingMonth.month}月まとめ請求書`);
    },
    [billingMonth.month, trigger, user.companyName]
  );

  useEffect(() => {
    if (billingsError) showErrorModal({ httpMethod: "get" });
    if (monthlyBillingSummaryError?.response?.status === 404) return;
    if (monthlyBillingSummaryError) showErrorModal({ httpMethod: "get" });
    if (attachmentError) showErrorModal({ httpMethod: "get" });
  }, [monthlyBillingSummaryError, billingsError, showErrorModal, attachmentError]);

  return (
    <Suspense fallback={<Loading />}>
      <MonthlyBillingListPresenter
        monthlyBillingSummary={monthlyBillingSummary}
        yearsOfUse={yearsOfUse}
        monthly={monthly}
        billingYear={billingYear}
        setBillingYear={setBillingYear}
        billingMonth={billingMonth}
        setBillingMonth={setBillingMonth}
        onClickBillingDownloadButton={onClickBillingDownloadButton}
        searchKeyword={searchKeyword}
        setSearchKeyword={setSearchKeyword}
        chosenAccount={chosenAccount}
        setChosenAccount={setChosenAccount}
        chosenDepartment={chosenDepartment}
        setChosenDepartment={setChosenDepartment}
        billingSort={billingSort}
        accounts={accountList}
        departments={departments}
        onClickSort={onClickSort}
        billingList={billingList}
        currentPageNumber={currentPageNumber}
        setCurrentPageNumber={setCurrentPageNumber}
        totalPageNumber={pagination.totalPages}
        onClickRow={toUsageDetail}
        billingClosingDate={billingClosingDate}
      />
    </Suspense>
  );
};

export default MonthlyBillingListContainer;
