/**
 * base64エンコードされた文字列に変換する
 * @param file
 * @returns
 */
export const convertFileToBase64String = async (file: File) => {
  const promise = new Promise<{ filename: string; content: string }>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      try {
        resolve({
          filename: file.name,
          content: (reader.result as string).split(",")[1],
        });
      } catch (err) {
        reject(err);
      }
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsDataURL(file);
  });

  return await promise;
};

/**
 * Base64とMIMEコンテンツタイプからBlobオブジェクトを作成する。
 * 日本語対応。
 * 参考サイト：https://www.hos.co.jp/blog/20170213/
 *
 * @param base64
 * @param mime_ctype MIMEコンテンツタイプ
 * @returns Blob
 */
export const toBlob = (base64: string, mime_ctype: string) => {
  // 日本語の文字化けに対処するためBOMを作成する。
  const bom = new Uint8Array([0xef, 0xbb, 0xbf]);

  const bin = atob(base64.replace(/^.*,/, ""));
  const buffer = new Uint8Array(bin.length);
  for (let i = 0; i < bin.length; i++) {
    buffer[i] = bin.charCodeAt(i);
  }
  // Blobを作成
  let blob: Blob;
  try {
    blob = new Blob([bom, buffer.buffer], {
      type: mime_ctype,
    });
  } catch (e) {
    return false;
  }
  return blob;
};

export const contractPdf = (pdfList: Attachment[]): Attachment | undefined => {
  let result;
  pdfList.forEach((pdf) => {
    if ("filename" in pdf && pdf.filename === "contract.pdf") {
      result = pdf;
    }
  });
  return result;
};

/**
 * 引数のファイルを直接ダウンロードする関数
 * @param pdfFile ダウンロードするファイル、あるいはundefined
 * @param filename 指定するファイル名
 * @returns boolean 直接ダウンロードできたかを返す
 */
export const downloadPdf = (pdfFile: Attachment | undefined, filename: string): boolean => {
  if (!pdfFile || !("content" in pdfFile)) return false;

  const blob = toBlob(pdfFile.content, "application/pdf");
  if (!blob) return false;

  const fileURL = window.URL.createObjectURL(blob);
  const alink = document.createElement("a");
  alink.href = fileURL;
  alink.download = filename + ".pdf";
  alink.click();
  return true;
};
