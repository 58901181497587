import { lazy, Suspense, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Loading from "components/Loading";
import useFindContractById from "hooks/api/useFindContractById";
import useErrorModalStatusContext from "hooks/useErrorModalStatusContext";
import { isBrowser, isTablet } from "utils/deviceDetect";

const UsageDetailPresenter = lazy(() =>
  isBrowser
    ? import("pages/UsageDetail/pc/UsageDetailPresenter")
    : isTablet
      ? import("pages/UsageDetail/tb/UsageDetailPresenter")
      : import("pages/UsageDetail/sp/UsageDetailPresenter")
);

const UsageDetailContainer = () => {
  const parameter = useParams();
  const navigate = useNavigate();
  const { contract, error: contractDetailError } = useFindContractById(parameter.id ?? "");

  const { showErrorModal } = useErrorModalStatusContext();
  useEffect(() => {
    if (contractDetailError) {
      showErrorModal({ httpMethod: "get", onPostClose: () => navigate(-1) });
    }
  }, [contractDetailError, navigate, showErrorModal]);

  return (
    <Suspense fallback={<Loading />}>
      <UsageDetailPresenter contract={contract} onBack={() => navigate(-1)} />
    </Suspense>
  );
};

export default UsageDetailContainer;
